import { ContentServiceV1 } from '@oneaudi/content-service';
import { InvalidContentError, isContentFragment } from '@oneaudi/falcon-tools';
import { AppContent, EditorJsonContent, FalconContent } from '../../types';
import { prepareEvents } from './prepareEvents';
import { debug } from './debugging';

const DEFAULT_THEME_OPTION = 'Light';

function isFalconContent(content: FalconContent): content is FalconContent {
  // Check here for all required fields and return true, otherwise false
  return Boolean(isContentFragment(content));
}

export function mapContent(contentService: ContentServiceV1): AppContent {
  const content = contentService.getContent() as EditorJsonContent | FalconContent;

  if (content.__type === 'aem-headless') {
    // Perform runtime validation
    if (!isFalconContent(content)) {
      // Throw `InvalidContentError` here if provided content
      // is not valid. This will display as a special error message
      // in the frontend on author environments.
      throw new InvalidContentError('Content is invalid.');
    }

    // map CF content to internal structure
    return mapFalconContent(content);
  }

  // map FACE content to internal structure
  return mapEditorJsonContent(content);
}

/**
 * Map EditorJsonContent to AppContent
 *
 * @param content EditorJsonContent
 * @returns AppContent
 */
export function mapEditorJsonContent(content: EditorJsonContent): AppContent {
  debug('raw content', content);

  const rawEvents = content.eventsOption?.events || [];
  const events = rawEvents && rawEvents.length > 0 ? prepareEvents(rawEvents) : [];

  const mappedContent: AppContent = {
    theme: content.themeOption?.theme || DEFAULT_THEME_OPTION,
    headline: content.headingOption.headline,
    events,
  };

  debug('mapped content', mappedContent);

  return mappedContent;
}

/**
 * Map FalconContent to AppContent
 *
 * @param content FalconContent
 * @returns AppContent
 */
export function mapFalconContent(content: FalconContent): AppContent {
  debug('raw content', content);

  const rawEvents = content.fields.eventsOption_events
    ? content.fields.eventsOption_events.map((item) => ({
        eventTitle: item.fields.eventTitle,
        description: item.fields.description,
        startDate: item.fields.startDate,
        endDate: item.fields.endDate,
        location: item.fields.location,
        cta: {
          label: item.fields.cta_label,
          url: item.fields.cta_url,
          ariaLabel: item.fields.cta_ariaLabel,
          openInNewTab: item.fields.cta_openInNewTab,
        },
      }))
    : [];

  const events = rawEvents && rawEvents.length > 0 ? prepareEvents(rawEvents) : [];

  const mappedContent: AppContent = {
    theme: content.fields.themeOption_theme || DEFAULT_THEME_OPTION,
    headline: content.fields.headingOption_headline,
    events,
  };

  debug('mapped content', mappedContent);

  return mappedContent;
}
