import { EventItem } from '../../types';
import { filterEvents } from './filterEvents';
import { sortEvents } from './sortEvents';

const EVENTS_CAP = 3;

const cutEvents = (events: EventItem[]): EventItem[] => {
  return events.slice(0, EVENTS_CAP);
};

export const prepareEvents = (events: EventItem[]) => {
  const filteredEvents = sortEvents(filterEvents(events));

  return cutEvents(filteredEvents);
};
